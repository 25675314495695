import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'
import { Section, Wysiwyg, Heading } from 'src/components/Base'

import {
  Hero,
  Branches,
  Departments,
  OurExpertise,
  AboutUs,
  OurServices,
  Logos,
  NewsEvents,
} from 'src/sections'

const Home = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspHome.homeIntro
  const { branches } = page.atspBranches
  const departments = page.atspHome.homeDepartments
  const service = page.atspHome.homeServices
  const expertise = page.atspHome.homeExpertise
  const about = page.atspHome.homeAboutUs
  const newsEvents = page.atspHome.homeNewsEvents
  const logos = page.atspHome.homeLogos

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero
        imageData={hero.heroImage.localFile}
        heading={hero.heroHeading}
        isLarge
      />

      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
      </Section>

      <Branches
        cardText={branches.wysiwyg}
        introText={branches.wysiwygIntro}
        heading={branches.heading}
        hasFirstCard
      />
      <Departments
        backgroundImageData={departments.backgroundImage.localFile}
        introText={departments.wysiwyg}
        heading={departments.heading}
        departmentsRepeater={departments.repeater}
      />
      <OurServices
        heading={service.heading}
        contentLeft={service.wysiwyg}
        imageData={service.image.localFile}
        link={service.link}
      />
      <AboutUs text={about.wysiwyg} heading={about.heading} />
      <OurExpertise
        contentLeft={expertise.wysiwyg}
        cardsArray={expertise.repeater}
        heading={expertise.heading}
      />
      <NewsEvents
        heading={newsEvents.heading}
        introText={newsEvents.wysiwyg}
        allNewsText={newsEvents.allNewsText}
        allEventsText={newsEvents.allEventsText}
      />
      <Logos
        heading={logos.heading}
        logos={logos.repeater}
        button={logos.button ? logos.button : undefined}
        hasBackground
      />
    </>
  )
}

Home.propTypes = {
  content: PropTypes.string,
}

Home.defaultProps = {
  content: '',
}

export const pageQuery = graphql`
  query HomeById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      content
      seo {
        title
        metaDesc
      }
      atspHome {
        homeLogos {
          heading
          button {
            url {
              ... on WpPage {
                id
                link
              }
            }
            title
          }
          repeater {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                )
                }
              }
            }
          }
        }

        homeIntro {
          heading
          wysiwyg
        }

        homeAboutUs {
          heading
          wysiwyg
        }

        homeExpertise {
          heading
          wysiwyg
          repeater {
            heading
            text
          }
        }
        homeNewsEvents {
          heading
          wysiwyg
          allNewsText
          allEventsText
        }
        homeServices {
          heading
          wysiwyg
          link {
            ... on WpPage {
              id
              uri
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        homeNewsEvents {
          heading
          wysiwyg
        }

        homeDepartments {
          heading
          wysiwyg
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          repeater {
            icon {
              localFile {
                publicURL
              }
            }
            heading
            link {
              ... on WpPage {
                id
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
          }
        }
      }
      atspBranches {
        branches {
          heading
          wysiwygIntro
          wysiwyg
        }
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
  }
`

export default Home
